/** DONE */
import Header from "./Components/Header";
import FullNavigationLine from "./Components/FullNavigationLine";
import SatelliteDemandMap from '../../../img/satelite-demand-map.jpg';

import { Howl } from 'howler';
import Module1VoiceTrack4 from '../../../media/module-01_voice-track-04.wav';

export const SlideVoice4 = new Howl({
	src: [Module1VoiceTrack4],
})
export const Slide4 = (props) => {
    return (
        <div className="slide-container"
             style={{ backgroundImage: 'url(' + SatelliteDemandMap + ')', backgroundSize: 'cover', backgroundPosition: 'center' }}>

            <div className="slide-top-half-container-no-background">
                <Header { ...props } />
                <div className="slide-top-content-text-container slide-top-wider-text">
                    <div>
                            <h1>
                                Satellite Operators also need to predict vessel
                                traffic to make satellite demand service decisions
                            </h1>
                            <p>
                                It's part of the day job. Satellite Operators must ensure vessels have the
                                communications coverage they need while optimising power distribution.
                            </p>
                        </div>
                </div>

            </div>

            <FullNavigationLine leftArrow rightArrow { ...props } />

        </div>
    );
}

export default Slide4;
