import Header from "./Components/Header";
import FullNavigationLine from "./Components/FullNavigationLine";
import ServerRoom from '../../../img/server-room.jpg';
import { Howl } from 'howler';
import Module1VoiceTrack2 from '../../../media/module-01_voice-track-02.wav';

import "../Slides.css";

export const SlideVoice2 = new Howl({
	src: [Module1VoiceTrack2],
})
export const Slide2 = (props) => {
    return (
        <div className="slide-container">
            <div className="slide-top-half-container">
                <Header  { ...props } />

                <div className="slide-top-content-text-container">
                    <div>
                        <h1>Patterns of Life (POL)</h1>
                        <p>
                            POL is a data analysis method that collects and analyses data by taking past and current behaviour to predict future patterns in behaviour. During this module we'll be looking at the patterns in maritime vessel traffic to make predictions into the future.
                        </p>
                    </div>
                </div>
            </div>

            <FullNavigationLine leftArrow rightArrow { ...props } />

            <div
                className="slide-bottom-content-image opacity-50"
                style={{ backgroundImage: 'url(' + ServerRoom + ')', backgroundSize: 'cover' }}
            ></div>
        </div>
    );
}

export default Slide2;