import React from 'react';
import PropTypes from 'prop-types';
import CloseArrowBtn from '../../img/arrow-navigate-story.png';
import './CloseArrow.css';

export const CloseArrow = () => {
	return (
		<>
			<div className="close-arrow-container">
				<div>
					<img src={CloseArrowBtn} alt="Close Arrow" />
				</div>
				<div className="close-arrow">
					<p>Close</p>
				</div>
			</div>
		</>
	);
};

CloseArrow.propTypes = {};

CloseArrow.defaultProps = {};

export default CloseArrow;
