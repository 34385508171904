/* DONE */
import Header from "./Components/Header";
import FullNavigationLine from "./Components/FullNavigationLine";
import LearnMoreModal from '../../LearnMoreModal/LearnMoreModal';
import LearnMoreLineTree from '../../../img/learn-more-tree-line.svg';
import SatellitesAndEarthMap from '../../../img/satelites-and-earth.png';
import { Howl } from 'howler';
import Module1VoiceTrack11 from '../../../media/module-01_voice-track-11.wav';

export const SlideVoice11 = new Howl({
	src: [Module1VoiceTrack11],
})
export const Slide11 = (props) => {
    const LearnMore = {
        modalBtnText: 'to learn more about',
        modalContentHeading: 'SUSTAINABILITY',
        modalContentText:
            'RF spectrum is an essential, but finite natural resource. Optimizing spectrum usage allows operators to sustainably grow their networks, reaching more users around the world without needing to launch more satellites.',
        globeImageClass: 'modal-button-globe-container',
    };
    return (
        <div className="slide-container">
            <div className="slide-top-half-container">
                <Header {...props} />
                <div className="slide-top-content">
                    <div className="slide-top-content-text-container slide-top-narrow-text">
                        <div>
                            <h1>
                                What can Satellite Operators
                                do with these predictions?
                            </h1>
                            <p>
                                Satellite Operators now have more accurate information during
                                demand prediction activities. This allows operators to optimise
                                service efficiency and power distribution among satellites to
                                ensure all customers have service when and where needed.
                            </p>
                        </div>
                    </div>

                    <div
                        className="slide11-modal-container"
                    >
                        <LearnMoreModal
                            modalBtnText={LearnMore.modalBtnText}
                            modalContentHeading={LearnMore.modalContentHeading}
                            modalContentText={LearnMore.modalContentText}
                            globeImageClass={LearnMore.globeImageClass}
                            box2layout={{ position: 'relative', left: '10rem', bottom: '31rem' }}
                            sustainablity={{ color: '#76c8ae' }}
                        />
                    </div>
                </div>
            </div>
            <FullNavigationLine leftArrow rightArrow {...props} />
                <div className="slide11-find-out-more-line">
                    <img src={LearnMoreLineTree} alt="Learn more line Tree" />
                </div>
            <div
                className="slide-bottom-content-image"
                style={{ backgroundImage: 'url(' + SatellitesAndEarthMap + ')', backgroundSize: 'cover' }}
            />
        </div>

    );
}

export default Slide11;