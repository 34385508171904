/* DONE */
import Header from "./Components/Header";
import FullNavigationLine from "./Components/FullNavigationLine";
import BarChart from '../../../img/bar-chart.png';
import WorldMap from '../../../img/world-map.png';
import CGIAIAdvanced360LogoPatternsOfLifeWhite from '../../../img/cgi-aIadvance360-main-logo-patterns-of-life-white.png';

import { Howl } from 'howler';
import Module1VoiceTrack10 from '../../../media/module-01_voice-track-10.wav';

export const SlideVoice10 = new Howl({
	src: [Module1VoiceTrack10],
})
export const Slide10 = (props) => {
    return (
        <div className="slide-container">
            <div className="slide-top-half-container">
                <Header {...props} />
                <div className="slide-top-content">
                    <div className="slide-top-content-text-container slide-top-narrower-text">
                        <div>
                            <img src={CGIAIAdvanced360LogoPatternsOfLifeWhite} alt="CGI AISAdvanced360 Patterns of Life Logo" className="pol-logo" />
                            <h1>4) The statistics</h1>
                            <p>
                                Our model has proven to predict ship location 28% more
                                accurately when compared to other machine learning approaches.
                            </p>
                        </div>
                    </div>
                    <div className="story-content-info-bar">
                        <img src={BarChart} alt="Bar Chart" style={{ position: 'relative', top: '-20.2vh' }} />
                    </div>
                </div>
            </div>

            <FullNavigationLine leftArrow rightArrow {...props} />

            <div
                className="slide-bottom-content-image opacity-50"
                style={{ backgroundImage: 'url(' + WorldMap + ')', backgroundSize: 'cover' }}
            />
        </div>

    );
}

export default Slide10;