/* DONE */
import Header from "./Components/Header";
import FullNavigationLine from "./Components/FullNavigationLine";
import WorldMap from '../../../img/world-map.png';
import Globe from '../../../img/globe.png';
import { Howl } from 'howler';
import Module1VoiceTrack13 from '../../../media/module-01_voice-track-13.wav';

export const SlideVoice13 = new Howl({
	src: [Module1VoiceTrack13],
})
export const Slide13 = (props) => {
    return (
        <div className="slide-container">
        <div className="slide-top-half-container">
            <Header {...props} />
            <div className="slide-top-content">
                <div className="slide-top-content-text-container slide-top-narrower-text">
                    <div>
                            <h1 >The race to a sustainable future</h1>
                            <p>
                                CGI AIAdvance360 supports the rapid exploration and development of
                                models like those used in Patterns of Life. This drastically reduces the
                                time required to see results and optimises the process of using AI to
                                support the development of <strong>smart</strong>, <strong>sustainable systems</strong> for the <strong>future</strong>.
                            </p>
                    </div>
                </div>
                <div className="story-content-info-globe">
                    <img src={Globe} alt="Globe" />
                </div>
            </div>
            </div>

            <FullNavigationLine leftArrow rightArrow {...props} />

            <div
                className="slide-bottom-content-image opacity-20"
                style={{ backgroundImage: 'url(' + WorldMap + ')', backgroundSize: 'cover'}}
            />
        </div>

    );
}

export default Slide13;