import Header from "./Components/Header";
import HalfNavigationLine from "./Components/HalfNavigationLine";
import { Howl } from 'howler';
import Module1VoiceTrack1 from '../../../media/module-01_voice-track-01.wav';

import "../Slides.css";

export const SlideVoice1 = new Howl({
	src: [Module1VoiceTrack1],
})
export const Slide1 = (props) => {
    return (
        <div className="slide-container">
            <div className="slide-top-half-container">
                <Header annotate { ...props } />
            </div>

            <HalfNavigationLine rightArrow annotate { ...props }>
                <div className="slide_introduction">
                    <div>
                        <h1>Module 1:</h1>
                        <h2>Patterns of Life</h2>
                        <h2>
                            <br />
                        </h2>
                        <p>Storytelling Mode</p>
                    </div>
                </div>
            </HalfNavigationLine>
        </div>
    );
}

export default Slide1;