import Navigation from "./Navigation";
import "./Navigation.js";

export const HalfNavigationLine = (props) => {
    /** Half Navigation Line component - allow for using the same code on each slide */
    return (
        <div className="navigation-full-width" style={{position:'relative'}}>
            <div className="navigation-line-halfright-container">
                <div className="navigation-line-circle"></div>
                <div className="navigation-line">
                    <Navigation {...props} type="halfRight"/>
                </div>
            </div>
            {props.children}
        </div>
    );
};

export default HalfNavigationLine;