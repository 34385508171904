import React, { useState, useEffect, useRef, useMemo } from 'react';
import Slider from 'react-slick';
import './Module1.css';

import VolumeOff from '../../img/volume-off-state.png';
import VolumeOn from '../../img/volume-on-state.png';

import { Slide1, SlideVoice1 } from "./Slides/Slide1";
import { Slide2, SlideVoice2 } from "./Slides/Slide2";
import { Slide3, SlideVoice3 } from "./Slides/Slide3";
import { Slide4, SlideVoice4 } from "./Slides/Slide4";
import { Slide5, SlideVoice5 } from "./Slides/Slide5";
import { Slide6, SlideVoice6 } from "./Slides/Slide6";
import { Slide7, SlideVoice7 } from "./Slides/Slide7";
import { Slide8, SlideVoice8 } from "./Slides/Slide8";
import { Slide9, SlideVoice9 } from "./Slides/Slide9";
import { Slide10, SlideVoice10 } from "./Slides/Slide10";
import { Slide11, SlideVoice11 } from "./Slides/Slide11";
import { Slide12, SlideVoice12 } from "./Slides/Slide12";
import { Slide13, SlideVoice13 } from "./Slides/Slide13";
import { Slide14 } from "./Slides/Slide14";

// Voice Tests
// Website https://projectpages.voicebooking.com/
// Settings: English GB, Female 5, speed and pitch default centered

export const Module1 = (props) => {
	const carouselSlider = useRef(null);

	/**
	 * SLIDE MOVEMENT
	 */
	const [direction, setDirection] = useState(false);

	const showPrevious = (isVertical) => {
		// Challenge is that if direction change we need to rerender before we can move
		// This is asynchronous, and not easily done in a single function.
		if (isVertical !== direction) {
			changeDirection(isVertical, carouselSlider.current.slickPrev);
		} else {
			// move to previous slide
			carouselSlider.current.slickPrev();
		}
	};

	const showNext = (isVertical) => {
		// Challenge is that if direction change we need to rerender before we can move
		// This is asynchronous, and not easily done in a single function.
		if (isVertical !== direction) {
			changeDirection(isVertical, carouselSlider.current.slickNext);
		} else {
			carouselSlider.current.slickNext();
		}
	};

	const sleep = (ms) => {
		// basic function for sleeping
		return new Promise((resolve) => setTimeout(resolve, ms));
	};

	const changeDirection = async (isVertical, fn) => {
		setDirection(isVertical);

		await sleep(500); // basically you need the current slider to rerender so that your change in direction will work properly. so we cheat.

		carouselSlider && fn();
	};

	/**
	 * SLIDE AUDIO SUPPORT
	 * This isn't real state, but since carousel renders everything all the time,
	 * we don't have much choice here. We can use Memo to avoid reinitialising.
	 */

	let [voiceOver, setVoiceOver] = useState(false);
	let [currentSlide, setCurrentSlide] = useState(0);
	let [voiceMuted, setVoiceMuted] = useState(true);
	let [voiceIcon, setVoiceIcon] = useState(VolumeOn);

	const voiceTracks = useMemo(() => [
		{ id: 0, voiceTrack: SlideVoice1 },
		{ id: 1, voiceTrack: SlideVoice2 },
		{ id: 2, voiceTrack: SlideVoice3 },
		{ id: 3, voiceTrack: SlideVoice4 },
		{ id: 4, voiceTrack: SlideVoice5 },
		{ id: 5, voiceTrack: SlideVoice6 },
		{ id: 6, voiceTrack: SlideVoice7 },
		{ id: 7, voiceTrack: SlideVoice8 },
		{ id: 8, voiceTrack: SlideVoice9 },
		{ id: 9, voiceTrack: SlideVoice10 },
		{ id: 10, voiceTrack: SlideVoice11 },
		{ id: 11, voiceTrack: SlideVoice12 },
		{ id: 12, voiceTrack: SlideVoice13 },
	], []);

	useEffect(() => {
		setVoiceOver(props.carouselMode);
	}, [props.carouselMode]);

	useEffect(() => {
		const playAudio = () => {
			voiceTracks.forEach((voice) => {
				if (voice.id === currentSlide && voiceOver === true) {
					voice.voiceTrack.pause();
					voice.voiceTrack.play();
				}
			});
		};
		playAudio();
	}, [voiceOver, currentSlide, voiceTracks]);

	const stopAllAudio = () => {
		voiceTracks.forEach((voice) => {
			voice.voiceTrack.stop();
		});
	};

	const muteAllAudio = () => {
		voiceTracks.forEach((voice) => {
			voice.voiceTrack.mute(voiceMuted);
		});
		setVoiceIcon(voiceMuted ? VolumeOff : VolumeOn);
		setVoiceMuted(!voiceMuted);
	};

	/**
	 * Slide Management and Configuration
	 */

	const settings = {
		dots: false,
		infinite: true,
		speed: 2000,
		slidesToShow: 1,
		slidesToScroll: 1,
		draggable: false,
		afterChange: (next) => {
			setCurrentSlide(next);
		},
		beforeChange: (current, next) => {
			stopAllAudio();
		},
		verticalSwiping: false,
		vertical: false
	};

	const resetModal = () => {
		carouselSlider.current.slickGoTo(0);
		props.hideModal();
		stopAllAudio();
	};

	// Properties used by header and navigation bars
	const navigationProps = {
		"resetModal":resetModal,
		"showPrevious":showPrevious,
		"showNext":showNext,
		"voiceIcon":voiceIcon,
		"muteAllAudio":muteAllAudio,
		"HORIZONTAL":false,
		"VERTICAL":true
	};

	return (
		<>
			<div className="carousel-container">
				<div className="carousel-slider-horizontal-container">
					<Slider ref={carouselSlider} {...settings} vertical={direction}>
						<Slide1 {...navigationProps} />
						<Slide2 {...navigationProps} />
						<Slide3 {...navigationProps} />
						<Slide4 {...navigationProps} />
						<Slide5 {...navigationProps} />
						<Slide6 {...navigationProps} />
						<Slide7 {...navigationProps} />
						<Slide8 {...navigationProps} />
						<Slide9 {...navigationProps} />
						<Slide10 {...navigationProps} />
						<Slide11 {...navigationProps} />
						<Slide12 {...navigationProps} />
						<Slide13 {...navigationProps} />
						<Slide14 {...navigationProps} />
					</Slider>
				</div>
			</div>
		</>
	);
};

export default Module1;
