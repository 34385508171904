import React from 'react';
import HomeIcon from '../../img/home-icon.svg';
import './HomeNavBar.css';

export const HomeNavBar = () => {
	return (
		<>
			<header>
				<img src={HomeIcon} alt="Home Icon" />

				<div className="nav-links">
					<p className="selected">AIAdvance360</p>
				</div>
			</header>
		</>
	);
};

export default HomeNavBar;
