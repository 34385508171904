/* DONE */
import Header from "./Components/Header";
import FullNavigationLine from "./Components/FullNavigationLine";
import CGIAIAdvanced360LogoPatternsOfLifeWhite from '../../../img/cgi-aIadvance360-main-logo-patterns-of-life-white.png';
import ProcessDataTrainModelMap from '../../../img/process-data-train-model-map.png';

import { Howl } from 'howler';
import Module1VoiceTrack8 from '../../../media/module-01_voice-track-08.wav';

export const SlideVoice8 = new Howl({
	src: [Module1VoiceTrack8],
})
export const Slide8 = (props) => {
    return (
        <div className="slide-container">
                <div className="slide-top-half-container">
                    <Header {...props} />
                    <div className="slide-top-content-text-container slide-top-wider-text">
                        <div >
                            <img src={CGIAIAdvanced360LogoPatternsOfLifeWhite} alt="CGI AISAdvanced360 Patterns of Life Logo" className="pol-logo" />
                            <h1>2) Processing the data &amp; training the model</h1>
                            <p>
                                We then process this data using AIAdvance360 and feed the data into a generic model, which is then trained to produce a bespoke, best-in-class model which is especially good for time series analysis.
                            </p>
                        </div>
                    </div>
                </div>

                <FullNavigationLine leftArrow rightArrow {...props} />

            <div
                className="slide-bottom-content-image"
                style={{ backgroundImage: 'url(' + ProcessDataTrainModelMap + ')', backgroundSize: 'cover' }}
            />
        </div>

    );
}

export default Slide8;