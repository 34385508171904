import React from 'react';
import PropTypes from 'prop-types';
import AudioOnOffIconImage from '../../img/audio-on-off-icon.png';
import './AudioOnOffIcon.css';

export const AudioOnOffIcon = () => {
	return (
		<>
			<div className="audio-toggle-container">
				<img src={AudioOnOffIconImage} alt="Audio On Off Icon" />
			</div>
		</>
	);
};

AudioOnOffIcon.propTypes = {};

AudioOnOffIcon.defaultProps = {};

export default AudioOnOffIcon;
