import AudioOnOffIcon from "../../../AudioOnOffIcon/AudioOnOffIcon";
import AudioButton from "../../../AudioButton/AudioButton"
import ArrowExitStory from '../../../ArrowExitStory/ArrowExitStory';
import ExitButton from '../../../ExitButton/ExitButton';
import "./Header.css";

export const Header = (props) => {
	/** Header component - allow for using the same code on each slide */
	const audioAnnotation = !props.annotate ? (
		''
	) : (
		<div className="audio-on-off">
			<AudioOnOffIcon />
		</div>
	);
	const exitAnnotation = !props.annotate ? (
		''
	) : (
		<div className="slide-header-annotation">
			<div className="exit-story-container">
				<div className="arrow-exit">
					<ArrowExitStory />
				</div>
			</div>
		</div>
	);
	return (
		<>
			<div className="slide-header">
				<div>
					<h1>Module 1: Patterns of Life</h1>
					<section>
						<div className="audio-button" onClick={props.muteAllAudio}>
							<AudioButton voiceIcon={props.voiceIcon} />
						</div>
						{audioAnnotation}
					</section>
				</div>

				<div className="exit-btn">
					<button onClick={() => props.resetModal()}>
						<ExitButton />
					</button>
				</div>
			</div>
			{exitAnnotation}
		</>
	);
};
export default Header;