import React from 'react';
import ESALogoImage from '../../img/esa-main-logo.png';
import './ESALogo.css';

export const ESALogo = () => {
	return (
		<>
			<div className="esa-logo-container">
				<img src={ESALogoImage} alt="ESA Logo" />
			</div>
		</>
	);
};

export default ESALogo;
