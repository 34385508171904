/* DONE */
import Header from "./Components/Header";
import FullNavigationLine from "./Components/FullNavigationLine";
import LearnMoreModal from '../../LearnMoreModal/LearnMoreModal';
import FindOutMoreLineTree from '../../../img/find-out-more-line-tree.svg';
import PortOperatorsMap from '../../../img/port-operators-map.png';
import { Howl } from 'howler';
import Module1VoiceTrack12 from '../../../media/module-01_voice-track-12.wav';

export const SlideVoice12 = new Howl({
	src: [Module1VoiceTrack12],
})
export const Slide12 = (props) => {
    const LearnMore = {
        modalBtnText: 'to find out more about',
        modalContentHeading: 'SUSTAINABILITY',
        modalContentText:
            'It is not efficient to have ships burn fuel at maximum vessel speed to only arrive at a port and have to wait due to lack of docking space. Smart steaming allows vessels to adjust their speed depending on vessel traffic patterns at the destination port. This significantly reduces journey fuel consumption and reduces port congestion.',
        globeImageClass: 'modal-button-globe-container',
    };
    return (
        <div className="slide-container">
            <div className="slide-top-half-container">
                <Header {...props} />
                <div className="slide-top-content">
                    <div className="slide-top-content-text-container slide-top-very-narrow-text">
                        <div>
                            <h1>
                                What can Port Operators do with this information?
                            </h1>
                            <p>
                                Port Operators can use this information to optimise
                                ship schedules and improve port management.
                            </p>
                        </div>
                    </div>
                    <div className="slide12-modal-container">
                        <LearnMoreModal
                            modalBtnText={LearnMore.modalBtnText}
                            modalContentHeading={LearnMore.modalContentHeading}
                            modalContentText={LearnMore.modalContentText}
                            globeImageClass={LearnMore.globeImageClass}
                            box2layout={{
                                position: 'relative',
                                left: '10vw',
                                bottom: '27rem',
                                width: '80rem',
                            }}
                            modal2FontSize={{ fontSize: '3rem' }}
                            sustainablity={{ color: '#76c8ae' }}
                        />
                    </div>
                </div>
            </div>
            <FullNavigationLine leftArrow rightArrow {...props}>
                 <div className="slide12-find-out-more-line">
                        <img src={FindOutMoreLineTree} alt="Find out more line tree" />
                </div>
            </FullNavigationLine>

            <div
                className="slide-bottom-content-image"
                style={{ backgroundImage: 'url(' + PortOperatorsMap + ')', backgroundSize: 'cover' }}
            />
        </div>

    );
}

export default Slide12;