import Header from "./Components/Header";
import StoryLeftBtnManual from '../../../img/story-left-btn.png';
import StoryDownBtnManual from '../../../img/story-down-btn.png';
import FocusAreaMap from '../../../img/focus-area-mask.png';

import { Howl } from 'howler';
import Module1VoiceTrack6 from '../../../media/module-01_voice-track-06.wav';
import "./Components/Navigation.css";

export const SlideVoice6 = new Howl({
	src: [Module1VoiceTrack6],
})
export const Slide6 = (props) => {
    const navigationElements = (
        <>
        <div
            className="navigation-line-circle-full-width-height slide-6-circle"
        ></div>
        <div
            className="custom-story-button slide-6-left-arrow"
            onClick={() => props.showPrevious(props.HORIZONTAL)}
        >
            <img src={StoryLeftBtnManual} alt="Story Left Button" />
        </div>
        <div
            className="custom-story-button slide-6-down-arrow"
            onClick={() => props.showNext(props.VERTICAL)}
        >
            <img src={StoryDownBtnManual} alt="Story Down Button" />
        </div>
        </>
    );
    return (
    <div className="slide-container">
        <div className="slide-floating-header">
            <Header {...props} />
        </div>
        {navigationElements}
        <div className="slide-column-container">
            <div className="slide-column-one">
                <div className="top">
                    <div className="slide-top-content-text-container">
                        <div>
                            <h1>
                            With the power of Artificial Intelligence, we are able to make these predictions
                            </h1>
                            <p>
                                Let us start with a focus area.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                </div>
            </div>
            <div className="slide-column-two"
                style={{
                    backgroundImage: 'url(' + FocusAreaMap + ')',
                    backgroundSize: 'cover',
                    position: 'relative',
                    height: '100vh',
                }}
            />

        </div>
    </div>
    );
}

export default Slide6;