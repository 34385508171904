import React from 'react';
import PropTypes from 'prop-types';
import './AudioButton.css';

export const AudioButton = ({ voiceIcon, ...props }) => {
	return (
		<>
			<div className="volume">
				<img src={voiceIcon} alt="Volume Icon" />
			</div>
		</>
	);
};

AudioButton.propTypes = {
	voiceIcon: PropTypes.string.isRequired,
};

AudioButton.defaultProps = {};

export default AudioButton;
