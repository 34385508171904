/* DONE */
import Header from "./Components/Header";
import HalfNavigationLineEnd from "./Components/HalfNavigationLineEnd";

export const Slide14 = (props) => {
    return (
        <div className="slide-container">
            <div className="slide-top-half-container">
                <Header annotate {...props} />
            </div>

            <HalfNavigationLineEnd leftArrow {...props} >
                <div className="slide_end">
                    <h1>The end.</h1>
                </div>
            </HalfNavigationLineEnd>
        </div>

    );
}

export default Slide14;