import React, { useRef } from 'react';
import './DataCubeModal.css';
import HeatMapsPrediction6 from '../../img/heatmaps-prediction-6.svg';
import HeatMapsPrediction12 from '../../img/heatmaps-prediction-12.svg';
import HeatMapsPrediction18 from '../../img/heatmaps-prediction-18.svg';
import HeatMapsPrediction24 from '../../img/heatmaps-prediction-24.svg';
import HeatMapsGroundTruth6 from '../../img/heatmaps-ground-truth-6.svg';
import HeatMapsGroundTruth12 from '../../img/heatmaps-ground-truth-12.svg';
import HeatMapsGroundTruth18 from '../../img/heatmaps-ground-truth-18.svg';
import HeatMapsGroundTruth24 from '../../img/heatmaps-ground-truth-24.svg';
import HeatMapsTruePrediction6 from '../../img/heatmaps-true-prediction-6.svg';
import HeatMapsTruePrediction12 from '../../img/heatmaps-true-prediction-12.svg';
import HeatMapsTruePrediction18 from '../../img/heatmaps-true-prediction-18.svg';
import HeatMapsTruePrediction24 from '../../img/heatmaps-true-prediction-24.svg';
import DataCubeLegend from '../../img/legend-component.png';
import CargoShipIcon from '../../img/cargo-ship-icon.svg';
import CloseArrow from '../CloseArrow/CloseArrow';
import ExitButton from '../ExitButton/ExitButton';
import CGIAIAdvanced360LogoPatternsOfLifeWhite from '../../img/cgi-aIadvance360-main-logo-patterns-of-life-white.png';

export const DataCubeModal = (props) => {
	const heatMapModal = useRef(null);

	const closeHeatMapModal = () => {
		heatMapModal.current.classList.add('hide');
		heatMapModal.current.classList.remove('show');
	};
	const openHeatMapModal = () => {
		heatMapModal.current.classList.remove('hide');
		heatMapModal.current.classList.add('show');
	};
	return (
		<>
			<div>
				<div ref={heatMapModal} className="overlay hide">
					<div className="data-cube-modal-container">
						<div className="data-cube-content">
							<div className="data-cubes">
								<div className="data-cubes-6">
									<div>
										<h1>+ 6 hours</h1>
										<img src={HeatMapsPrediction6} alt="Heatmap CGI AIAdvance360 prediction +6 hours" />
									</div>
									<div>
										<img src={HeatMapsGroundTruth6} alt="Heatmap Ground truth +6 hours" />
									</div>
									<div>
										<img src={HeatMapsTruePrediction6} alt="Heatmap True/prediction difference +6 hours" />
									</div>
								</div>
								<div className="data-cubes-12">
									<div>
										<h1>+ 12 hours</h1>
										<img src={HeatMapsPrediction12} alt="Heatmap CGI AIAdvance360 prediction +12 hours" />
									</div>
									<div>
										<img src={HeatMapsGroundTruth12} alt="Heatmap Ground truth +12 hours" />
									</div>
									<div>
										<img src={HeatMapsTruePrediction12} alt="Heatmap True/prediction difference +12 hours" />
									</div>
								</div>
								<div className="data-cubes-18">
									<div>
										<h1>+ 18 hours</h1>
										<img src={HeatMapsPrediction18} alt="Heatmap CGI AIAdvance360 prediction +18 hours" />
									</div>
									<div>
										<img src={HeatMapsGroundTruth18} alt="Heatmap Ground truth +18 hours" />
									</div>
									<div>
										<img src={HeatMapsTruePrediction18} alt="Heatmap True/prediction difference +18 hours" />
									</div>
								</div>
								<div className="data-cubes-24">
									<div>
										<h1>+ 24 hours</h1>
										<img src={HeatMapsPrediction24} alt="Heatmap CGI AIAdvance360 prediction +24 hours" />
									</div>
									<div>
										<img src={HeatMapsGroundTruth24} alt="Heatmap Ground truth +24 hours" />
									</div>
									<div>
										<img src={HeatMapsTruePrediction24} alt="Heatmap True/prediction difference +24 hours" />
									</div>
								</div>
								<div className="data-cubes-description">
									<div>
										<img src={CGIAIAdvanced360LogoPatternsOfLifeWhite} alt="CGI AIAdvance360 Patterns of Life Logo" />
										<p>Predicted using CGI's bespoke Machine Learning Model</p>
										<p>[Lighter areas show fewer vessels predicted]</p>
									</div>
									<div>
										<h1>Ground truth</h1>
										<p>Ground truth (i.e. where vessels actually went)</p>
										<p>[Darker areas show more vessels]</p>
									</div>
									<div>
										<h1>True/prediction difference</h1>
										<p>(Lighter colours show more accurate predictions)</p>
									</div>
								</div>
							</div>
							<div className="data-cube-legend-container">
								<div className="data-cube-legend-inner">
									<div className="data-cube-legend">
										<img src={DataCubeLegend} alt="Data Cube Legend" />
									</div>
									<div className="data-cube-cargo-ship-icon">
										<img src={CargoShipIcon} alt="Cargo Ship Icon" />
									</div>
								</div>
							</div>
						</div>
						<div className="close-modal-container">
							<div className="close-modal-exit-button" onClick={() => closeHeatMapModal()}>
								<ExitButton />
							</div>
							<div className="close-modal-close-arrow">
								<CloseArrow />
							</div>
						</div>
					</div>
				</div>
				<div className="open-modal-container">
					<div className="open-modal-box-area" onClick={() => openHeatMapModal()}></div>
				</div>
			</div>
		</>
	);
};

DataCubeModal.propTypes = {};

DataCubeModal.defaultProps = {};

export default DataCubeModal;
