/* DONE */
import Header from "./Components/Header";
import FullNavigationLine from "./Components/FullNavigationLine";
import DataCubeModal from '../../DataCubeModal/DataCubeModal';
import CGIAIAdvanced360LogoPatternsOfLifeWhite from '../../../img/cgi-aIadvance360-main-logo-patterns-of-life-white.png';
import VisualisationMap from '../../../img/visualisation-map.png';

import { Howl } from 'howler';
import Module1VoiceTrack9 from '../../../media/module-01_voice-track-09.wav';

export const SlideVoice9 = new Howl({
	src: [Module1VoiceTrack9],
})
export const Slide9 = (props) => {
    return (
        <div className="slide-container">
            <div className="slide-top-half-container">
                <Header {...props} />
                <div className="slide-top-content-text-container">
                    <div>
                        <img src={CGIAIAdvanced360LogoPatternsOfLifeWhite} alt="CGI AISAdvanced360 Patterns of Life Logo" className="pol-logo" />
                        <h1>3) Visualisation of results</h1>
                        <p>
                            Once trained, we are able to make vessel traffic predictions in our focus area for the next 24 hours.
                        </p>
                    </div>
                </div>
            </div>

            <FullNavigationLine leftArrow rightArrow {...props} />

            <div className="slide9-data-cube-modal-overlay-container">
                <section>
                    <DataCubeModal />
                </section>
            </div>

            <div
                className="slide-bottom-content-image"
                style={{ backgroundImage: 'url(' + VisualisationMap + ')', backgroundSize: 'cover' }}
            ></div>
        </div>

    );
}

export default Slide9;