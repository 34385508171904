import React, { useRef, useState } from 'react';
import './App.css';
import HomeBackground from './stories/HomeBackground/HomeBackground';
import SwipeUp from './stories/SwipeUp/SwipeUp';
import ModuleList from './stories/ModuleList/ModuleList';
import ModuleOneImage from '../src/img/module-1-img.png';
import ModuleTwoImage from '../src/img/module-2-img.png';
import CGILogo from '../src/stories/CGILogo/CGILogo';
import CGIAIAdvanced360Logo from '../src/img/cgi-aIadvance360-main-logo.png';
import ESALogo from '../src/stories/ESALogo/ESALogo';
import ModuleMaskImageGreen from '../src/img/module-green-mask.png';
import ModuleMaskImageGrey from '../src/img/module-grey-mask.png';
import Module1 from './stories/Module1/Module1';
import UKSpaceAgencyLogo from './stories/UKSpaceAgencyLogo/UKSpaceAgencyLogo';

const App = () => {
	const [carouselMode, setCarouselMode] = useState(false);
	const [showMain, setShowMain] = useState(true);
	const supportFullScreen = useRef(null);

	const showModal = () => {
		window.scrollTo(0, 0);
		setShowMain(false);
		supportFullScreen.current && supportFullScreen.current.requestFullscreen();
		setCarouselMode(true);
	};
	const hideModal = () => {
		window.scrollTo(0, 0);
		setShowMain(true);
		document.exitFullscreen();
	};
	const data = [
		{
			id: 1,
			clickHandler: showModal,
			img: ModuleOneImage,
			module: 'Module 1',
			title: 'Patterns of Life',
			description: 'See AIAdvance360 in action through a use case',
			moduleMask: ModuleMaskImageGreen,
			moduleStatus: 'active-module',
			moduleStatusDescription: 'active-module-description',
			moduleStatusFill: '#ffffff',
			moduleStatusOpacity: 1,
		}
		/*,
		{
			id: 2,
			img: ModuleTwoImage,
			module: 'Module 2',
			title: 'A Deeper Dive',
			description: 'Learn the science behind AIAdvance360 and machine learning (coming soon)',
			moduleMask: ModuleMaskImageGrey,
			moduleStatus: 'disabled-module',
			moduleStatusDescription: 'disabled-module',
			moduleStatusFill: '#ffffff',
			moduleStatusOpacity: 0.5,
		},*/
	];
	return (
		<div ref={supportFullScreen}>
			{showMain && (
				<div>
					<HomeBackground />
					<section className="home-intro-section-container">
					<div className="intro-container">
						<div>
							<div className="divider"></div>
						</div>
						<article>
							<img src={CGIAIAdvanced360Logo} alt="CGI AUTSS Logo" />
							<h1>BUILT THROUGH AN ESA, UK SPACE AGENCY AND CGI COLLABORATION</h1>
							<p>
								With the power of AI, CGI AIAdvance360 provides the architecture, tooling
								and methodology to develop Machine Learning use cases from ideation
								to service in a greatly accelerated timeframe - taking days
								compared to months and years.
							</p>
						</article>
					</div>
						<SwipeUp />
					</section>
					<section className="module-section-container">
						<h1 className="find-out-more-heading">Find out more</h1>
						<ModuleList data={data} />
					</section>
					<section className="logo-section-container">
						<ESALogo />
						<UKSpaceAgencyLogo />
						<CGILogo />
					</section>
				</div>
			)}
			{!showMain && (
				<div className="carousel-fixed-top">
					<Module1 hideModal={hideModal} carouselMode={carouselMode} />
				</div>
			)}
		</div>
	);
};

export default App;
