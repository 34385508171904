import React from 'react';
import PropTypes from 'prop-types';
import ArrowExitStoryBtn from '../../img/arrow-exit-story.png';
import './ArrowExitStory.css';

export const ArrowExitStory = () => {
	return (
		<>
			<div className="arrow-exit-story-container">
				<div>
					<img src={ArrowExitStoryBtn} alt="Arrow Exit Story" />
				</div>
				<div className="arrow-exit-story">
					<p>
						Exit Story <br />
						Telling Mode
					</p>
				</div>
			</div>
		</>
	);
};

ArrowExitStory.propTypes = {};

ArrowExitStory.defaultProps = {};

export default ArrowExitStory;
