import React from 'react';
import './HomeBackground.css';
import HomeNavBar from '../HomeNavBar/HomeNavBar';

export const HomeBackground = () => {
	return (
		<>
			<div className="home-background-container">
				<div>
					<HomeNavBar />
				</div>
				<div className="home-background-ocean" />
			</div>
		</>
	);
};

export default HomeBackground;
