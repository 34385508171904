import React from 'react';
import PropTypes from 'prop-types';
import SwipeUpImage from '../../img/swipe-up.png';
import './SwipeUp.css';

export const SwipeUp = () => {
	return (
		<>
			<div className="swipe-up-container">
				<img src={SwipeUpImage} alt="Swipe Up" />
			</div>
		</>
	);
};

SwipeUp.propTypes = {};

SwipeUp.defaultProps = {};

export default SwipeUp;
