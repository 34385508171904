/* DONE */
import Header from "./Components/Header";
import FullNavigationLine from "./Components/FullNavigationLine";
import LearnMoreModal from '../../LearnMoreModal/LearnMoreModal';

import CargoShip from '../../../img/cargo-ship.jpg';
import FindOutMoreLine from '../../../img/find-out-more-line.svg';

import { Howl } from 'howler';
import Module1VoiceTrack3 from '../../../media/module-01_voice-track-03.wav';

export const SlideVoice3 = new Howl({
	src: [Module1VoiceTrack3],
})
export const Slide3 = (props) => {
    const LearnMore = {
        modalBtnText: 'to find out how predictions are currently made',
        modalContentHeading: 'PREDICTING TRAFFIC TODAY',
        modalContentText:
            'Near to shore Port Operators may use local 5G networks. Further away, satellite communication is used.',
        globeImageClass: 'hide',
    };
    return (
        <div className="slide-container">
            <div className="slide-top-half-container">
                <Header {...props} />

                <div className="slide-top-content">
                    <div className="slide-top-content-text-container">
                        <div>
                            <h1>
                                Port Operators need to predict vessel
                                traffic to make port management decisions
                            </h1>
                            <p>
                                Port Operators need to manage port capacity and make decisions
                                based on the number of vessels coming in and out of the port.
                            </p>
                        </div>
                    </div>
                    <div className="slide3-modal-container">
                        <LearnMoreModal
                            modalBtnText={LearnMore.modalBtnText}
                            modalContentHeading={LearnMore.modalContentHeading}
                            modalContentText={LearnMore.modalContentText}
                            globeImageClass={LearnMore.globeImageClass}
                            sustainablity={{ display: 'none' }}
                        />
                    </div>
                </div>


            </div>

            <FullNavigationLine leftArrow rightArrow  {...props}>
                <div className="slide3-find-out-more-line">
                    <img src={FindOutMoreLine} alt="Find out more line" />
                </div>
            </FullNavigationLine>

            <div
                className="slide-bottom-content-image opacity-50"
                style={{ backgroundImage: 'url(' + CargoShip + ')', backgroundSize: 'cover' }}
            >
            </div>
        </div>
    );
}

export default Slide3;