import Navigation from "./Navigation";
import "../../Slides.css";
import "./Navigation.css";

export const FullNavigationLine = (props) => {
    /** Full Navigation Line component with image underneath - allow for using the same code on each slide */
    /* This could, perhaps, also be rendered as a background image with attribute 'cover' */
    return (
        <div className="slide-bottom-half-container">
            <div className="navigation-line-container-full-width">
                <div className="navigation-line-circle-full-width"></div>
                <div className="navigation-line-full-width"></div>
                <Navigation {...props} />
            </div>
            {props.children}
        </div>
    );
};

export default FullNavigationLine;