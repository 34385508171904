import React from 'react';
import PropTypes from 'prop-types';
import UKSpaceAgencyLogoImage from '../../img/UKSA-logo-rgb-transparent.png';
import './UKSpaceAgencyLogo.css';

export const UKSpaceAgencyLogo = () => {
	return (
		<>
			<div className="ukspaceagency-logo-container">
				<img src={UKSpaceAgencyLogoImage} alt="UK Space Agency Logo" />
			</div>
		</>
	);
};

UKSpaceAgencyLogo.propTypes = {};

UKSpaceAgencyLogo.defaultProps = {};

export default UKSpaceAgencyLogo;
