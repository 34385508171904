/** DONE */
import Header from "./Components/Header";
import FullNavigationLine from "./Components/FullNavigationLine";
import MaritimeVesselTrafficMap from '../../../img/maritime-vessel-traffic-map.png';

import { Howl } from 'howler';
import Module1VoiceTrack5 from '../../../media/module-01_voice-track-05.wav';

export const SlideVoice5 = new Howl({
	src: [Module1VoiceTrack5],
})
export const Slide5 = (props) => {
    return (
        <div className="slide-container"
             style={{ backgroundImage: 'url(' + MaritimeVesselTrafficMap + ')', backgroundSize: 'cover' }}>

            <div className="slide-top-half-container-no-background">
                <Header { ...props } />
                <div className="slide-top-content-text-container slide-top-very-narrow-text">
                    <div>
                        <h1>
                            It is not easy to predict this maritime vessel traffic
                        </h1>
                        <p>
                            There are many dynamic factors that need to be considered including sudden changes
                            in the weather and event driven demand.
                        </p>
                    </div>
                </div>
            </div>
            <FullNavigationLine leftArrow rightArrow { ...props } />

        </div>
    );
}

export default Slide5;