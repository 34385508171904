import React from 'react';
import PropTypes from 'prop-types';
import ModuleButton from '../ModuleButton/ModuleButton';
import './ModuleList.css';

export const ModuleList = ({ data, ...props }) => {
	return (
		<>
			<div className="module-list-container">
				{data.map((module) => (
					<div key={module.id}>
						<div {...props}>
							<ModuleButton
								clickHandler={module.clickHandler}
								img={module.img}
								module={module.module}
								title={module.title}
								description={module.description}
								moduleMask={module.moduleMask}
								moduleStatus={module.moduleStatus}
								moduleStatusDescription={module.moduleStatusDescription}
								moduleStatusFill={module.moduleStatusFill}
								moduleStatusOpacity={module.moduleStatusOpacity}
							/>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

ModuleList.propTypes = {
	data: PropTypes.array,
	img: PropTypes.string,
};

ModuleList.defaultProps = {};

export default ModuleList;
