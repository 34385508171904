import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './LearnMoreModal.css';
import 'animate.css';
import GlobeImage from '../../img/globe.png';

export const LearnMoreModal = ({
	modalBtnText,
	modalContentHeading,
	modalContentText,
	globeImageClass,
	box2layout,
	sustainablity,
	modal2FontSize,
	...props
}) => {
	const toggleModal = useRef(null);
	const box1 = useRef(null);
	const box2 = useRef(null);

	const modalBoxOneHandler = () => {
		box2.current.classList.add('show');
		box2.current.classList.remove('hide');
		box1.current.classList.add('hide');
		box1.current.classList.remove('show');
		// toggleModal.current.classList.add('animate__zoomIn');
	};
	const modalBoxTwoHandler = () => {
		box1.current.classList.add('show');
		box1.current.classList.remove('hide');
		box2.current.classList.add('hide');
		box2.current.classList.remove('show');
		// toggleModal.current.classList.remove('animate__zoomIn');
		// toggleModal.current.classList.add('animate__bounceIn');
	};
	return (
		<>
			<div ref={toggleModal} className="toggle-modal animate__animated" {...props}>
				<div ref={box1} className="box1" onClick={modalBoxOneHandler}>
					<div>
						<div>
							<div className="chevron-container">
								<div>
									<div className="chevron-white-box-horizontal"></div>
								</div>

								<div>
									<div className="chevron-white-box-horizontal"></div>
								</div>
							</div>
							<div className="chevron-container">
								<div>
									<div className="chevron-white-box-vertical"></div>
								</div>

								<div>
									<div className="chevron-white-box-vertical"></div>
								</div>
							</div>
						</div>
						<div className="modal-button-container">
							<div>
								<p>
									<span>Click</span> {modalBtnText} <span style={sustainablity}>sustainability</span>
								</p>
							</div>
						</div>
						<div>
							<div className="chevron-container">
								<div>
									<div className="chevron-white-box-vertical"></div>
								</div>

								<div>
									<div className="chevron-white-box-vertical"></div>
								</div>
							</div>
							<div className="chevron-container">
								<div>
									<div className="chevron-white-box-horizontal"></div>
								</div>

								<div>
									<div className="chevron-white-box-horizontal"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div style={box2layout} ref={box2} className="box2 hide" onClick={modalBoxTwoHandler}>
					<div>
						<div>
							<div className="chevron-container">
								<div>
									<div className="chevron-white-box-horizontal"></div>
								</div>

								<div>
									<div className="chevron-white-box-horizontal"></div>
								</div>
							</div>
							<div className="chevron-container">
								<div>
									<div className="chevron-white-box-vertical"></div>
								</div>

								<div>
									<div className="chevron-white-box-vertical"></div>
								</div>
							</div>
						</div>
						<div className="modal-button-container">
							<div>
								<h1 style={modal2FontSize}>{modalContentHeading}</h1>
								<p style={modal2FontSize}>{modalContentText}</p>
								{/* <div className={globeImageClass}>
									<img src={GlobeImage} alt="Globe" />
								</div> */}
							</div>
						</div>
						<div>
							<div className="chevron-container">
								<div>
									<div className="chevron-white-box-vertical"></div>
								</div>

								<div>
									<div className="chevron-white-box-vertical"></div>
								</div>
							</div>
							<div className="chevron-container">
								<div>
									<div className="chevron-white-box-horizontal"></div>
								</div>

								<div>
									<div className="chevron-white-box-horizontal"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

LearnMoreModal.propTypes = {
	modalBtnText: PropTypes.string,
	modalContentHeading: PropTypes.string,
	modalContentText: PropTypes.string,
	globeImageClass: PropTypes.string,
	modal2FontSize: PropTypes.object,
};
LearnMoreModal.defaultProps = {};

export default LearnMoreModal;
