import ArrowNavigateStory from '../../../ArrowNavigateStory/ArrowNavigateStory';
import StoryLeftBtnManual from '../../../../img/story-left-btn.png';
import StoryRightBtnManual from '../../../../img/story-right-btn.png';

import "./Navigation.css";

export const Navigation = (props) => {

    const HORIZONTAL = false;
    let className = "navigation-buttons-container";
    if (props?.type === "halfRight") {
        className = "navigation-buttons-container-right";
    } else if (props?.type === "halfLeft") {
        className = "navigation-buttons-container-left";
    }

    /** Header component - allow for using the same code on each slide */
    const leftArrow = !props?.leftArrow ? "" : (
        <div
            className="left-arrow"
            onClick={() => props.showPrevious(HORIZONTAL)}
        >
            <img src={StoryLeftBtnManual} alt="Story Left Button" />
        </div>
    );
    const rightArrow = !props?.rightArrow ? "" : (
        <div
            className="right-arrow"
            onClick={() => props.showNext(HORIZONTAL)}
        >
            <img src={StoryRightBtnManual} alt="Story Right Button" />
        </div>
    );
    const annotation = (!props.annotate) ? "" : (
        <div className="annotation-next-container">
            <div className="arrow-next">
                <ArrowNavigateStory />
            </div>
        </div>
    )

    return (
        <div
            className={className}
        >
        {leftArrow}
        {rightArrow}
        {annotation}
        </div>
    );
};

export default Navigation;