/* NEEDS REFACTORED */
import Header from "./Components/Header";
import CGIAIAdvanced360LogoPatternsOfLifeWhite from '../../../img/cgi-aIadvance360-main-logo-patterns-of-life-white.png';
import StoryUpBtnManual from '../../../img/story-up-btn.png';
import StoryRightBtnManual from '../../../img/story-right-btn.png';
import CollectionDataImage from '../../../img/content-component-bottom.png';

import { Howl } from 'howler';
import Module1VoiceTrack7 from '../../../media/module-01_voice-track-07.wav';

export const SlideVoice7 = new Howl({
	src: [Module1VoiceTrack7],
})
export const Slide7 = (props) => {
    const navigationElements = (
        <>
        <div
            className="navigation-line-circle-full-width-height slide-7-circle"
        ></div>
        <div
            className="custom-story-button slide-7-up-arrow"
            onClick={() => props.showPrevious(props.VERTICAL)}
        >
            <img src={StoryUpBtnManual} alt="Story Up Button" />
        </div>
        <div
            className="custom-story-button slide-7-right-arrow"
            onClick={() => props.showNext(props.HORIZONTAL)}
        >
            <img src={StoryRightBtnManual} alt="Story Right Button" />
        </div>
        </>
    );
    return (
        <div className="slide-container">
            <div className="slide-floating-header">
                <Header {...props} />
            </div>
            {navigationElements}
            <div className="slide-column-container-first-row">
                <div className="slide-column-one bordered">
                    <div className="slide-top-content-text-container">
                        <div>
                            <img
                                src={CGIAIAdvanced360LogoPatternsOfLifeWhite}
                                alt="CGI AISAdvanced360 Patterns of Life Logo"
                                className="pol-logo"
                            />
                            <h1>1) Collecting the data</h1>
                            <p>
                                First we need to collect data from our focus area and transform
                                this into a 3D data cube.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="slide-column-two-boundary"></div>
            </div>
            <div>
                <div className="slide-7-navigation-line"></div>
            </div>
            <div
                className="slide-bottom-half-container"
                style={{
                    position: 'relative',
                    backgroundImage: 'url(' + CollectionDataImage + ')',
                    backgroundClip: 'cover',
                    right: '0',
                    backgroundSize: '100%',
                    backgroundColor: 'var(--esa-primary-colour)'
                }}
            >
            </div>
        </div>
    );
}

export default Slide7;