import React from 'react';
import PropTypes from 'prop-types';
import ExitButtonImage from '../../img/exit-btn.png';
import './ExitButton.css';

export const ExitButton = () => {
	return (
		<>
			<div className="exit-button-container">
				<img src={ExitButtonImage} alt="Exit Button" />
			</div>
		</>
	);
};

export default ExitButton;
