import React from 'react';
import ArrowNavigateStoryBtn from '../../img/arrow-navigate-story.png';
import './ArrowNavigateStory.css';

export const ArrowNavigateStory = () => {
	return (
		<>
			<div className="arrow-navigate-story-container">
				<div>
					<img src={ArrowNavigateStoryBtn} alt="Arrow Navigate Story" />
				</div>
				<div className="arrow-navigate-story">
					<p>
						Navigate to next <br />
						part of story
					</p>
				</div>
			</div>
		</>
	);
};

export default ArrowNavigateStory;
