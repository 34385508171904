import React from 'react';
import PropTypes from 'prop-types';
import ModuleOneImage from '../../img/module-1-img.jpg';
import ModuleBGShadow from '../../img/module-bg-shadow.png';
import './ModuleButton.css';
import ModuleMaskImage from '../../img/module-green-mask.png';

export const ModuleButton = ({
	clickHandler,
	img,
	module,
	title,
	description,
	moduleMask,
	moduleStatus,
	moduleStatusDescription,
	moduleStatusFill,
	moduleStatusOpacity,
	...props
}) => {
	return (
		<>
			<div className="module-container" onClick={clickHandler} {...props}>
				<div className="module-image-bg">
					<img src={img} alt="Module Background" />
					<div className="module-background-shadow">
						<img src={ModuleBGShadow} alt="Module Background" />
					</div>
					<div className="module-content" style={{ backgroundImage: `url(${moduleMask})` }}>
						<div className="module-details">
						<p className={moduleStatus}>{module}</p>
						<h1 className={moduleStatus}>{title}</h1>
						<div className="description-container">
								<p className={`${moduleStatusDescription}`}>{description}</p>
								<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 91.953 98.181">
									<path
										id="right-chevron"
										d="M45.977,0C20.584,0,0,21.979,0,49.09s20.584,49.09,45.977,49.09S91.953,76.2,91.953,49.09,71.369,0,45.977,0ZM56.629,51.26,43.7,65.067a2.775,2.775,0,0,1-2.032.9c-2.537,0-3.847-3.3-2.032-5.238l10.9-11.637-10.9-11.637a3.217,3.217,0,0,1,0-4.339,2.746,2.746,0,0,1,4.064,0L56.629,46.921a3.217,3.217,0,0,1,0,4.339Z"
										fill={`${moduleStatusFill}`}
										opacity={`${moduleStatusOpacity}`}
									/>
								</svg>
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	);
};

/*
<div className="module-content-container">
						<div className="module-content" style={{ backgroundImage: `url(${moduleMask})` }}>
							<p className={`esa-bold ${moduleStatus}`}>{module}</p>
							<h1 className={`esa-bold ${moduleStatus}`}>{title}</h1>


						</div>
					</div>
					*/


/*
<div className="module-container" onClick={clickHandler} {...props}>
				<div className="module-image-bg" >
					<img src={img} alt="Module Background" />
					<div className="module-background-shadow">
						<img src={ModuleBGShadow} alt="Module Background" />
					</div>
					<div className="module-content-container">
						<div className="module-content" style={{ backgroundImage: `url(${moduleMask})` }}>
							<p className={`esa-bold ${moduleStatus}`}>{module}</p>
							<h1 className={`esa-bold ${moduleStatus}`}>{title}</h1>

							<div className="description-container">
								<p className={`${moduleStatusDescription}`}>{description}</p>
								<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 91.953 98.181">
									<path
										id="right-chevron"
										d="M45.977,0C20.584,0,0,21.979,0,49.09s20.584,49.09,45.977,49.09S91.953,76.2,91.953,49.09,71.369,0,45.977,0ZM56.629,51.26,43.7,65.067a2.775,2.775,0,0,1-2.032.9c-2.537,0-3.847-3.3-2.032-5.238l10.9-11.637-10.9-11.637a3.217,3.217,0,0,1,0-4.339,2.746,2.746,0,0,1,4.064,0L56.629,46.921a3.217,3.217,0,0,1,0,4.339Z"
										fill={`${moduleStatusFill}`}
										opacity={`${moduleStatusOpacity}`}
									/>
								</svg>
							</div>
						</div>
					</div>
				</div>
			</div>
*/

ModuleButton.propType = {
	img: PropTypes.string.isRequired,
	module: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	moduleMask: PropTypes.string.isRequired,
	moduleStatus: PropTypes.string.isRequired,
	moduleStatusDescription: PropTypes.string.isRequired,
	moduleStatusFill: PropTypes.string.isRequired,
	moduleStatusOpacity: PropTypes.number.isRequired,
};

ModuleButton.defaultProps = {
	img: ModuleOneImage,
	module: 'Module 1',
	title: 'Patterns of Life',
	description: 'See AUTSS in action through a use case',
	moduleMask: ModuleMaskImage,
	moduleStatus: 'active-module',
	moduleStatusDescription: 'active-module-description',
	moduleStatusFill: '#ffffff',
	moduleStatusOpacity: 1,
};

export default ModuleButton;
