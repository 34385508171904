import Navigation from "./Navigation";

export const HalfNavigationLineEnd = (props) => {
    /** Half Navigation Line component - allow for using the same code on each slide */
    return (
        <div className="navigation-full-width">
            <div className="navigation-line-halfright-container">
                <div className="navigation-line-circle"></div>
                <div className="navigation-line-end"></div>
                <Navigation {...props} type="halfLeft"/>
            </div>
            {props.children}
        </div>
    );
};

export default HalfNavigationLineEnd;